var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-project-reserved-units"},[_c('div',{staticClass:"container fluid"},[_c('h3',{staticClass:"py-3"},[_vm._v("Reserved Units")]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.reservedUnitTableColumns,"rows":_vm.reservedUnitTableData,"totalRows":_vm.reservedUnitTablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toManageReservations},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'reservationCount')?_c('span',[_c('span',{staticClass:"reservation-count mx-2"},[_vm._v(" "+_vm._s(props.row.numberOfPurchaser)+" ")])]):(props.column.field == 'project')?_c('span',[_c('project-unit-summary',{attrs:{"sale":_vm.getSale(props.row)}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no reserved units"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }