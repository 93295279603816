<template>
  <div class="view-project-reserved-units">
    <div class="container fluid">
      <h3 class="py-3">Reserved Units</h3>

      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="reservedUnitTableColumns"
          :rows="reservedUnitTableData"
          :totalRows="reservedUnitTablePagination.total"
          :isLoading="isLoading"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="toManageReservations"
        >
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <div slot="emptystate">
            <no-data message="There is no reserved units"></no-data>
          </div>
          <div slot="table-actions" class="p-2"></div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'reservationCount'">
              <span class="reservation-count mx-2">
                {{ props.row.numberOfPurchaser }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'project'">
              <project-unit-summary
                :sale="getSale(props.row)"
              ></project-unit-summary>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    ProjectUnitSummary: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/ProjectUnitSummary"
      )
  },
  mixins: [vueGoodTable],
  props: {
    manageReservationRouteName: {
      type: String,
      default: "ManageProjectReservations"
    },
    getReservedUnitsAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      reservedUnitTableData: [],
      reservedUnitTablePagination: {},
      reservedUnitTableColumns: [
        {
          label: "No. of Reserve",
          field: "reservationCount",
          width: "150px"
        },
        {
          label: "Project",
          field: "project"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },

      isLoading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllReservedUnits();
    },
    toManageReservations(param) {
      let unitId = param.row.id;
      this.$router.push({
        name: this.manageReservationRouteName,
        params: {
          id: unitId
        }
      });
    },
    getSale(reservedUnit) {
      const {
        projectUnitType,
        projectUnitFloorPlan,
        project,
        ...unit
      } = reservedUnit;

      return {
        projectUnit: unit,
        projectUnitType,
        projectUnitFloorPlan,
        project
      };
    },

    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllReservedUnits();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllReservedUnits().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openReservedUnitDetail(event) {
      this.currentReservedUnitDetail = event.row;
      this.isReservedUnitDetailShown = true;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllReservedUnits();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllReservedUnits();
    },
    // ========================== API Related =========================
    async getAllReservedUnits() {
      this.isLoading = true;
      try {
        // let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getReservedUnitsAPI({
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.reservedUnitTableData = this._.cloneDeep(data.data);
        this.reservedUnitTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get reserved units list. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.view-project-reserved-units {
  .reservation-count {
    background: $color-danger;
    box-shadow: 0px 4px 6px #00000030;
    color: white;
    border-radius: 6px;
    padding: 4px 12px;
    margin: 5px;
    text-align: center;
    font-weight: bold;
    width: fit-content;
  }
}
</style>
